<template>
  <div :class="theme">
    <template v-if="frnportalnoindex">
      <div class="frn_subPage">
        <div class="nameLineSub">Hallo {{ customerName }}!</div>
        <Button
          class="goto-mail"
          :text="getGotToAppText"
          :href="getGotToAppUrl"
        />
      </div>
    </template>
    <template v-else>
      <div class="wrapper">
        <div class="login-logo">{{ getTitle }}</div>
        <div class="nameLine">Hallo {{ customerName }}!</div>
        <br />
        <div v-if="world === '5'" class="frn_loginBoxTrenner"></div>
        <div class="buttons">
          <Button
            class="logout"
            light="true"
            text="LOGOUT"
            href="#"
            v-on:click.native="logout"
          />
          <Button
            class="goto-mail"
            :text="getGotToAppText"
            :href="getGotToAppUrl"
          />
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import Button from "./Button";
import FrnApiClient from "@frnde/frn-api-client";

export default {
  components: {
    Button,
  },
  props: {
    theme: String,
    world: String,
    tenent: String,
    frnportalnoindex: Boolean,
  },
  data() {
    return {
      loginname: "",
      password: "",
      customer: {},
    };
  },

  async mounted() {
    try {
      this.customer = await FrnApiClient.Customer.get();
    } catch (error) {
      this.customer.first_name = "Freenet";
      this.customer.first_name = "Kunde";
    }
  },
  computed: {
    customerName() {
      let name = "";
      if (this.customer.first_name) {
        name = this.customer.first_name;
      }
      if (this.customer.last_name) {
        name += " " + this.customer.last_name;
      }

      return name;
    },
    getGotToAppUrl() {
      let url = "https://webmail.freenet.de/web";
      switch (this.world) {
        case "5":
        case "4":
          url = "/web";
          break;
      }
      if (this.tenent === "freenetplus") {
        url = "/";
      }
      return url;
    },
    getGotToAppText() {
      let text = "ZUM POSTFACH";

      switch (this.world) {
        case "5":
          text = "DATENTRESOR";
          break;
        case "4":
          text = "ZUR CLOUD";
          break;
      }
      return text;
    },
    getTitle() {
      let title = "Login Mail & Cloud";
      switch (this.world) {
        case "5":
        case "4":
          title = "Login";
          break;
      }
      if (this.tenent === "freenetplus") {
        title = "Login";
      }
      return title;
    },
  },
  methods: {
    getLogoutTarget() {
      let url = "https://logout.freenet.de";
      if (this.tenent === "freenetcloud") {
        return url;
      }
      switch (this.world) {
        case "5":
          url = "/";
          break;
        case "4":
          url = "/logout";
          break;
      }
      return url;
    },
    logout() {
      let me = this;
      FrnApiClient.Auth.logout()
        .then(function () {
          top.location = me.getLogoutTarget();
        })
        .catch(function () {
          top.location = me.getLogoutTarget();
        });
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../scss/colors";

#frn-loginbox {
  .wrapper {
    padding: 11px 20px 9px 20px;
    text-align: left;

    .buttons {
      margin: 11px 0 0 0;
      display: flex;
      white-space: nowrap;
    }
  }

  .logout {
    flex: 1;
    margin-right: 12px;
  }
  .nameLine {
    color: $frn-white;
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
    white-space: nowrap;
  }

  .login-logo {
    margin-bottom: 13px;
    color: $frn-white;
    font-size: 22px;
  }

  .frn_loginBoxTrenner {
    border-bottom: solid 2px #c2de9a;
    margin: 0 0 3px;
    border-top: none;
    margin-left: -20px;
    margin-right: -20px;
  }

  .frn-white {
    .nameLine {
      color: $black-text !important;
    }
    .login-logo {
      color: $black-text !important;
    }
  }

  .frn_subPage {
    display: flex;
    justify-content: flex-end;
    padding: 0 6px 0;
    .nameLineSub {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 185px;
      line-height: 1rem;
      font-size: 1rem;
      white-space: nowrap;
      font-weight: 700;
      padding: 4px 10px 0 0;
    }
  }
}
</style>
